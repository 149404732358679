<template>
    <div class="flights-results-cont">
      <overlay v-if="$store.state.flightsTimeOut && flightsStore" @newSearch="newSearch" />
        <div class="background d-none d-md-block">
          <v-container>
            <div v-if="$store.state.flightType === 'one'" class="mb-5">
              <div class="d-flex">
                <div class="heading-md text-bold white--text">{{$store.state.airports.departureAirports[0].Name}}</div>
                <div class="heading-md text-bold white--text">/{{$store.state.airports.arrivalAirports[0].Name}}</div>
              </div>
            </div>
            <div v-if="$store.state.flightType === 'round'" class="mb-5">
              <div class="d-flex">
                <div class="heading-md text-bold white--text">{{$store.state.airports.departureAirports[0].Name}}</div>
                <div class="heading-md text-bold white--text">/{{$store.state.airports.arrivalAirports[0].Name}}</div>
              </div>
            </div>
            <div v-if="$store.state.flightType === 'multi'" class="mb-5">
              <div class="d-flex">
                <div class="heading-md text-bold white--text">{{$store.state.airports.departureAirports[0].Name}}/</div>
                <div class="heading-md text-bold white--text">{{$store.state.airports.arrivalAirports[0].Name}}</div>
                <div class="heading-md text-bold white--text">/{{$store.state.airports.arrivalAirports[1].Name}}</div>
                <div class="heading-md text-bold white--text" v-if="$store.state.airports.arrivalAirports[2]">{{$store.state.airports.arrivalAirports[2].Name}}</div>
              </div>
            </div>
            <search :loading="searchBtnLoad" @addFlights="getFlights" />
          </v-container>
        </div>
        <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
          <v-row>
            {{text}}
            <v-spacer></v-spacer>
            <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
          </v-row>
        </v-snackbar>
        <v-container fluid class="px-5 mt-10">
          <v-expansion-panels hover class="d-block d-md-none">
            <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-magnify">Search Flights</v-expansion-panel-header>
                <v-expansion-panel-content class="panel">
                  <search :loading="searchBtnLoad" @addFlights="getFlights" />
                </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- loader -->
          <v-row justify="center" v-if="!flightsStore && loaded" class="mb-10">
            <v-progress-circular
            :size="70"
            :width="7"
            class="mt-15"
            color="primary"
            indeterminate
            ></v-progress-circular>
          </v-row>

          <v-row v-if="flightsStore && !loaded">
              <v-col cols="12" class="d-block d-md-none mt-2">
                  <v-expansion-panels hover>
                      <v-expansion-panel v-if="flightsStore.length > 1">
                          <v-expansion-panel-header expand-icon="mdi-filter-menu">Filter Results</v-expansion-panel-header>
                          <v-expansion-panel-content>
                              <filters :alternateFlightsExist="alternateFlightsExist" v-if="showMatrix" @changed="filter" :mailonlyFaresExist="mailonlyFaresExist"/>
                              <p v-else class="text-center">Preparing Filters ...</p>
                          </v-expansion-panel-content>
                      </v-expansion-panel>
                  </v-expansion-panels>
              </v-col>
              <v-col cols="3" xl="2" class="d-none d-md-block" v-if="flightsStore.length > 1">
                <v-card class="pb-3" elevation="2" width="100%">
                  <filters v-if="showMatrix" @changed="filter" :alternateFlightsExist="alternateFlightsExist" :mailonlyFaresExist="mailonlyFaresExist"/>
                  <p v-else class="text-center">Preparing Filters ...</p>
                </v-card>
              </v-col>
              <v-col v-if="flights.length == 0 && !loaded" cols="12" :md="flightsStore.length === 1 ? 12 : 9">
                <p class="body-1 font-weight-bold mx-auto primary--text text-center">Sorry, No flights found . <span v-if="alternateDatesFound">Please wait we are preparing alternate date flights for you ...</span></p>
              </v-col>
              <v-col cols="12" :md="flightsStore.length === 1 ? 12 : 9" :xl="flightsStore.length === 1 ? 12 : 10"  v-if="flights.length > 0">
                  <!-- <div v-if="$store.state.flightType === 'one'" class="mb-5">
                    <div class="d-flex">
                      <div class="blueDark--text text-h5 font-weight-bold">{{$store.state.airports.departureAirports[0].Name}}</div>
                      <v-icon x-large color="blueDark" class="mx-5">mdi-ray-start-arrow</v-icon>
                      <div class="blueDark--text text-h5 font-weight-bold">{{$store.state.airports.arrivalAirports[0].Name}}</div>
                    </div>
                  </div>
                  <div v-if="$store.state.flightType === 'round'" class="mb-5">
                    <div class="d-flex">
                      <div class="blueDark--text text-h5 font-weight-bold">{{$store.state.airports.departureAirports[0].Name}}</div>
                      <v-icon x-large color="blueDark" class="mx-5">mdi-swap-horizontal</v-icon>
                      <div class="blueDark--text text-h5 font-weight-bold">{{$store.state.airports.arrivalAirports[0].Name}}</div>
                    </div>
                  </div>
                  <div v-if="$store.state.flightType === 'multi'" class="mb-5">
                    <div class="d-flex">
                      <div class="blueDark--text text-h5 font-weight-bold">{{$store.state.airports.departureAirports[0].Name}}</div>
                      <v-icon x-large color="blueDark" class="mx-5 pb-2">mdi-ray-start-arrow</v-icon>
                      <div class="blueDark--text text-h5 font-weight-bold">{{$store.state.airports.arrivalAirports[0].Name}}</div>
                      <v-icon x-large color="blueDark" class="mx-5 pb-2">mdi-ray-start-arrow</v-icon>
                      <div class="blueDark--text text-h5 font-weight-bold">{{$store.state.airports.arrivalAirports[1].Name}}</div>
                      <v-icon v-if="$store.state.airports.arrivalAirports[2]" x-large color="blueDark" class="mx-5 pb-2">mdi-ray-start-arrow</v-icon>
                      <div class="blueDark--text text-h5 font-weight-bold" v-if="$store.state.airports.arrivalAirports[2]">{{$store.state.airports.arrivalAirports[2].Name}}</div>
                    </div>
                  </div> -->
                  <!-- <matrix :alternateFlightsExist="alternateFlightsExist" @choose="filter" :netMatrix="netMatrix" :alterMatrix="alterMatrix" v-if="flightsStore.length > 1 && showMatrix"/> -->
                  <!-- <div style="width: 100%; text-align: center;">
                    <v-carousel height="100" :show-arrows="false" v-if="alternateLoad && alternateFlights.length === 0" continuous cycle interval="1000" hide-delimiters>
                      <v-carousel-item
                      v-for="(item, i) in $store.state.flightResults.FilterMatrix"
                      :key="i"
                      >
                        <v-img class="mx-auto" max-width="100" :src="item.AirlineLogo"></v-img>
                      </v-carousel-item>
                    </v-carousel>
                  </div> -->
                  <!-- <v-alert
                  color="warning"
                  dark
                  icon="mdi-alert"
                  border="left"
                  dismissible
                  >
                    COVID-19 alert: Travel requirements are changing rapidly. Including need for pre-travel COVID-19 testing and quarantine on arrival.
                  </v-alert> -->
                  <v-alert
                  color="success"
                  dark
                  icon="mdi-alert"
                  border="left"
                  dismissible
                  v-if="$store.state.flightResults.nonDirectFlightNote"
                  >
                    {{$store.state.flightResults.nonDirectFlightNote}}
                  </v-alert>
                  <v-divider v-if="!alternateLoad && alternateFlights.length > 0" class="my-5"></v-divider>
                  <flightCard :flights="flights" />
              </v-col>
          </v-row>
          <p v-if="!flightsStore && !loaded" class="body-1 font-weight-bold primary--text text-center mt-5">Sorry, No flights match your search. <span v-if="alternateDatesFound">Please wait we are preparing alternate date flights for you ...</span></p>
        </v-container>
    </div>
</template>

<script>
import search from '@/components/flights/searchForm.vue'
import flightCard from '@/components/flights/card.vue'
import filters from '@/components/flights/filters.vue'
import { searchFlights, headersNoAuth } from '@/links'

export default {
  title: '-Flight results',
  components: {
    search,
    flightCard,
    filters
  },
  data () {
    return {
      model: 0,
      flights: [],
      snackbar: false,
      color: '',
      text: '',
      alternateLoad: true,
      alternateFlights: [],
      netMatrix: [],
      alterMatrix: [],
      showMatrix: false,
      loaded: true,
      alternateDatesFound: true,
      searchBtnLoad: false,
      alternateFlightsExist: false,
      mailonlyFaresExist: false
    }
  },
  computed: {
    flightsStore () {
      return this.$store.state.flightResults.flights
    },
    min () {
      return this.$store.state.flightResults.minPrice
    },
    max () {
      return this.$store.state.flightResults.maxPrice
    }
  },
  watch: {
    flightsStore (newVal) {
      this.flights = newVal
    }
  },
  methods: {
    airLabel (name, code, price) {
      const label = name + code + price
      return label
    },
    filter (flights) {
      if (flights.length > 0) {
        this.flights = flights
      }
    },
    newSearch (data) {
      this.loaded = true
      this.getFlights(data)
    },
    getFlights (data) {
      this.showMatrix = false
      this.searchBtnLoad = true
      this.$store.dispatch('removeFlightResults')
      this.loaded = true
      this.alternateDatesFound = true
      this.$http.get(searchFlights(data), { headers: headersNoAuth() }).then(response => {
        this.$store.dispatch('setFlightsTimeOut', false)
        this.$store.dispatch('setFlightsSearchTimeoutFunction', setTimeout(() => {
          this.$store.dispatch('setFlightsTimeOut', true)
        }, 20 * 60 * 1000)
        )
        this.$store.dispatch('setFlightResults', response.data.data)
        if (response.data.data.flights.length > 0) this.loaded = false
        localStorage.setItem('alternateSearchTerm', data)
      }).then(() => {
        if (this.flightsStore) {
          const newArr = []
          if (this.$store.state.flightResults.flights.length > 0) {
            this.$store.state.flightResults.flights.forEach(flight => {
              if (flight.alternate === undefined && flight.netFlight === undefined) {
                newArr.push(flight)
              }
            })
          }
          this.flights = newArr
          this.searchBtnLoad = false
          this.alternateDatesFound = false
          this.alterMatrix = []
          this.flights = this.flights.reduce((acc, current) => {
            const x = acc.find(item => {
              if (item.mailOnlyFare) this.mailonlyFaresExist = true
              return (item.mailOnlyFare && current.mailOnlyFare && item.pricingInfo.TotalFare === current.pricingInfo.TotalFare)
            })
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          this.flights.sort((a, b) => {
            let x
            let y
            if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
            else x = a.pricingInfo.TotalFare
            if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
            else y = b.pricingInfo.TotalFare
            return x - y
          })
          this.$store.dispatch('updateFlights', this.flights)
          this.showMatrix = true
          this.loaded = false
        } else {
          this.searchBtnLoad = false
          this.alternateDatesFound = false
          this.alterMatrix = []
        }
      }, () => {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Could not find flights!'
        this.loaded = false
        this.searchBtnLoad = false
        this.alternateDatesFound = false
        this.alterMatrix = []
        this.alternateLoad = false
      })
    }
  },
  created () {
    window.scrollTo(0, 0)
    let data
    if (this.$route.params.term) data = this.$route.params.term
    else data = localStorage.getItem('alternateSearchTerm')
    this.getFlights(data)
  },
  beforeCreate () {
    if (!this.$route.params.term && !localStorage.getItem('alternateSearchTerm')) this.$router.push({ name: 'flights' })
  }
}
</script>

<style>
    .background {
        height: auto;
        min-height: fit-content;
        background: linear-gradient(#ff8f95d3, #ff8f95d3), url('../../assets/images/sea.jpg');
        background-color: #333;
        background-size: 100% 100%;
        background-position: center center;
    }
    .background .container {
      padding-top: 25px;
    }
    .blink-2 {
      -webkit-animation: blink-2 1.5s infinite both;
              animation: blink-2 1.5s infinite both;
    }
    .panel .v-expansion-panel-content__wrap {
      background: linear-gradient(#ff8f95d3, #ff8f95d3), url('../../assets/images/sea.jpg');
      background-size: 100% 100%;
    }
    @-webkit-keyframes blink-2 {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes blink-2 {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }
</style>
